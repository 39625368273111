import { Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import { FORMAT_DATE } from 'common/Constant';
import { END_DATE_LATER_THAN_START_DATE } from 'common/error';
import { Controller, useFormContext } from 'react-hook-form';
import { validateDate } from 'utils/dateUtil';
import ContactInfo from './contactInfo';

const useStyles = makeStyles((theme) => ({
  inputField: {
    margin: theme.spacing(1),
    '& .MuiInputBase-root': {
      height: '25px', 
    },
    width: '150px'
  },
  label: { 
    fontWeight: "500",
    marginRight: theme.spacing(1),
    alignSelf: 'center'
  },
  gridItem: { display: 'flex', justifyContent: 'space-around' }
}));

const AdminChart = ({ prefix }) => {
  const styles = useStyles();
  const { control, getValues } = useFormContext();

  return (
    <>
    <br />
    <div>
      <Typography color="primary">
        <strong style={{ fontSize: '1.15em' }}>{prefix.charAt(0).toUpperCase() + prefix.slice(1)} Feed:</strong>
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Grid container alignItems="center" className={styles.gridItem}>
            <Grid item>
              <label htmlFor={`${prefix}CarrierName`} className={styles.label}>
                Carrier Name
              </label>
            </Grid>
            <Grid item>
              <Controller
                name={`${prefix}CarrierName`}
                control={control}
                defaultValue=""
                rules={{
                  required: "Carrier Name is required"
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    margin="dense"
                    size="small"
                    id={`${prefix}-carrier-name`}
                    className={styles.inputField}
                    variant="outlined"
                    error={!!fieldState.error}
                    helperText={fieldState.error ? 'Carrier Name is required' : ''}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container alignItems="center" className={styles.gridItem}>
            <Grid item>
              <label htmlFor={`${prefix}StartDate`} className={styles.label} style={{ marginRight: '24px' }}>
                Start Date
              </label>
            </Grid>
            <Grid item>
              <Controller
                name={`${prefix}StartDate`}
                control={control}
                defaultValue=""
                rules={{
                  required: "Start Date can't be empty",
                  validate: validateDate
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    fullWidth
                    margin="dense"
                    size="small"
                    placeholder={FORMAT_DATE}
                    className={styles.inputField}
                    variant="outlined"
                    onBlur={field.onBlur}
                    error={!!error}
                    helperText={error?.message || ''}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container alignItems="center" className={styles.gridItem}>
            <Grid item>
              <label htmlFor={`${prefix}EndDate`} className={styles.label}>
                End Date{' '}
                <span className={styles.smallFont} style={{ marginRight: '32px' }}>
                  - optional
                </span>
              </label>
            </Grid>
            <Grid item>
              <Controller
                name={`${prefix}EndDate`}
                control={control}
                defaultValue=""
                rules={{
                  validate: (value) => {
                    if (!value) return true;

                    const errorMessage = validateDate(value);
                    if (errorMessage !== true) return errorMessage;

                    const startDate = new Date(getValues(`${prefix}StartDate`));
                    const endDate = new Date(value);
                    return endDate > startDate || END_DATE_LATER_THAN_START_DATE;
                  }
                }}
                placeholder={FORMAT_DATE}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    fullWidth
                    margin="dense"
                    size="small"
                    placeholder={FORMAT_DATE}
                    className={styles.inputField}
                    variant="outlined"
                    error={!!error}
                    helperText={error?.message || ''}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ContactInfo prefix={`${prefix}`} />
      <br />
    </div>
  </>
  );
};

export default AdminChart;