import { Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import ContactInfo from './contactInfo';

const useStyles = makeStyles((theme) => ({
  inputField: {
    margin: theme.spacing(1),
    '& .MuiInputBase-root': {
      height: '25px', 
    },
    width: '150px'
  },
  label: { 
    fontWeight: "500",
    marginRight: theme.spacing(1),
    alignSelf: 'center'
  },
  gridItem: { display: 'flex', justifyContent: 'space-around' }
}));

const MedicalChart = ({ prefix }) => {
  const styles = useStyles();
  const { control } = useFormContext();

  return (
    <>
      <br />
      <Typography color="primary">
        <strong style={{ fontSize: '1.15em' }}>{prefix.charAt(0).toUpperCase() + prefix.slice(1)} Feed:</strong>
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Grid container alignItems="center" className={styles.gridItem}>
            <Grid item>
              <label htmlFor={`${prefix}CarrierName`} className={styles.label}>
                Carrier Name
              </label>
            </Grid>
            <Grid item>
              <Controller
                name={`${prefix}CarrierName`}
                control={control}
                defaultValue=""
                rules={{
                  required: true
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    type="text"
                    className={styles.inputField}
                    variant="outlined"
                    id={`${prefix}CarrierName`}
                    error={!!fieldState.error}
                    helperText={fieldState.error ? 'Carrier Name is required' : ''}
                    maxLength="500"
                    size="25"
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ContactInfo prefix={`${prefix}`} />
    </>
  );
};

export default MedicalChart;