import React, { useEffect, useState, Fragment } from 'react';
import { Grid } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useForm, FormProvider } from 'react-hook-form';
import RoleTenantList from './roleTenantList';
import { v4 as uuid } from 'uuid';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails
} from 'common/BstAccordion';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

const UserPermissionDetailView = React.memo(
  ({ item, appList, tenantList, handleExpend }) => {
    const methods = useForm({ defaultValues: item });

    const [cloned, setCloned] = useState(item);

    const { reset } = methods;

    useEffect(() => {
      reset(item);
    }, [item]);

    const handleStateChange = (expend, section) => {
      if (handleExpend) {
        handleExpend(item, expend);
      }
      setCloned({ ...cloned, [section]: expend });
    };

    const isAppAdmin = (app) => {
      if (!app?.code) return item ? item.systemAdmin : false;
      switch (app.code) {
        case 'ADMIN':
          return item?.systemAdmin;
        case 'FRONT_OFFICE':
          return item?.foAdmin;
        case 'MASTER_DATA':
          return item?.mdAdmin;
        case 'RANGER':
          return item?.rangerAdmin;
        default:
          return item ? item.systemAdmin : false;
      }
    };

    return (
      <>
        <FormProvider {...methods}>
          <Fragment key={uuid()}>
            {appList && Array.isArray(appList) && appList.length > 0 ? (
              appList.filter((app) => app.code !== 'RANGER').map((app, index) => (
                <Fragment key={'key-' + index}>
                  <Accordion
                    defaultExpanded={
                      cloned ? cloned['default_' + app.code + '_open'] : false
                    }
                    onChange={(event, expend) =>
                      handleStateChange(
                        expend,
                        'default_' + app.code + '_open'
                      )
                    }
                    TransitionProps={{ unmountOnExit: true }}
                    key={'key-' + uuid()}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-label="Expand"
                      aria-controls={app.code + '-content'}
                      id={app.code + '-header'}
                      key={'key-' + uuid()}>
                      {item?.userRoleTenantDetail[String(app.id)] ||
                        isAppAdmin(app) ? (
                        <CheckIcon color="primary" />
                      ) : (
                        <ClearIcon color="secondary" />
                      )}
                      {app.description}
                    </AccordionSummary>
                    <AccordionDetails>
                      {
                        item?.systemAdmin ?
                        ( 
                          app.code !== 'ENIGMA' && app.code !== 'CLIENT_PORTAL' ?
                          ( <Grid container justifyContent="center" alignItems="center">
                              This user is already system admin!
                            </Grid> ) : (
                            <RoleTenantList
                              data={item.userRoleTenantDetail[String(app.id)]}
                              roleList={item.external ? app.roles : app.roles.filter((a) => !a.isExternalOnlyRole)}
                              tenantList={tenantList}
                              appName={app.description}
                              appId={app.id}
                              userId={item.id}
                            />                            
                          )
                        ) : (
                          isAppAdmin(app) ? (
                            <Grid
                              container
                              justifyContent="center"
                              alignItems="center">
                              This user is already admin under this app!
                            </Grid>
                          ) : (
                            <RoleTenantList
                              data={item.userRoleTenantDetail[String(app.id)]}
                              roleList={item.external ? app.roles : app.roles.filter((a) => !a.isExternalOnlyRole)}
                              tenantList={tenantList}
                              appName={app.description}
                              appId={app.id}
                              userId={item.id}
                            />
                          )
                        )
                      }
                    </AccordionDetails>
                  </Accordion>
                </Fragment>
              ))
            ) : (
              <>
                <Grid container justifyContent="center" alignItems="center">
                  <i>
                    Sorry! No application permission was found in the system.
                  </i>
                </Grid>
              </>
            )}
            <Accordion
              defaultExpanded={
                cloned
                  ? cloned['default_' + 'no_app_related_permission' + '_open']
                  : false
              }
              onChange={(event, expend) =>
                handleStateChange(
                  expend,
                  'default_' + 'no_app_related_permission' + '_open'
                )
              }
              TransitionProps={{ unmountOnExit: true }}
              key={uuid()}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls={'no_app_related_permission' + '-content'}
                id={'no_app_related_permission' + '-header'}
                key={'key-' + uuid()}>
                Additional permissions that not tie to applications
              </AccordionSummary>
              <AccordionDetails>
                { item?.systemAdmin ? 
                  ( <Grid container justifyContent="center" alignItems="center">
                    This user is already system admin!
                  </Grid> ) : (
                    isAppAdmin() ? (
                      <Grid container justifyContent="center" alignItems="center">
                        This user is already admin under this app!
                      </Grid>
                    ) : (
                      <Grid container justifyContent="center" alignItems="center">
                        <i>No additional permission was found in the system.</i>
                      </Grid>
                    )
                  )              
                }
              </AccordionDetails>
            </Accordion>
          </Fragment>
        </FormProvider>
      </>
    );
  }
);

export default UserPermissionDetailView;
