import { Grid, TextField, makeStyles } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  inputField: {
    margin: theme.spacing(1),
    '& .MuiInputBase-root': {
      height: '25px', 
    },
    width: '150px'
  },
  label: { 
    fontWeight: "500",
    marginRight: theme.spacing(1),
    alignSelf: 'center'
  },
  gridItem: { display: 'flex', justifyContent: 'space-around' }
}));

const ContactInfo = ({ prefix }) => {
  const styles = useStyles();
  const { control } = useFormContext();

  return (
    <Grid container spacing={3}>
      <Grid item xs={4}>
        <Grid container alignItems="center" className={styles.gridItem}>
          <Grid item>
            <label htmlFor={`${prefix}ContactName`} className={styles.label}>
              Contact Name
            </label>
          </Grid>
          <Grid item>
            <Controller
              name={`${prefix}ContactName`}
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  id={`${prefix}ContactName`}
                  required
                  fullWidth
                  className={styles.inputField}
                  variant="outlined"
                  size="small"
                  margin="dense"
                  error={!!fieldState.error}
                  helperText={fieldState.error ? 'Contact Name is required' : ''}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Grid container alignItems="center" className={styles.gridItem}>
          <Grid item>
            <label htmlFor={`${prefix}ContactEmail`} className={styles.label}>
              Contact Email
            </label>
          </Grid>
          <Grid item>
            <Controller
              name={`${prefix}ContactEmail`}
              control={control}
              defaultValue=""
              rules={{
                required: true,
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                  message: 'Invalid email address'
                }
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  id={`${prefix}ContactEmail`}
                  required
                  fullWidth
                  className={styles.inputField}
                  variant="outlined"
                  size="small"
                  margin="dense"
                  error={!!fieldState.error}
                  helperText={fieldState.error ? (fieldState.error.type === 'pattern' ? 'Invalid email address' : 'Contact Email is required') : ''}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Grid container alignItems="center" className={styles.gridItem}>
          <Grid item>
            <label htmlFor={`${prefix}ContactPhone`} className={styles.label}>
              Contact Phone Number
            </label>
          </Grid>
          <Grid item>
            <Controller
              name={`${prefix}ContactPhone`}
              control={control}
              defaultValue=""
              rules={{
                required: true,
                pattern: {
                  value: /^[0-9\b\-()]+$/,
                  message: 'Invalid phone number'
                }
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  id={`${prefix}ContactPhone`}
                  required
                  fullWidth
                  className={styles.inputField}
                  variant="outlined"
                  size="small"
                  margin="dense"
                  error={!!fieldState.error}
                  helperText={fieldState.error ? (fieldState.error.type === 'pattern' ? 'Invalid phone number' : 'Contact Phone Number is required') : ''}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ContactInfo;