import { DATE_YEAR_REGEX, FORMAT_DATE, INVALID_DATE_YEAR_REGEX, MM_DD_YYYY_DATE_REGEX } from 'common/Constant';
import { INVALID_DATE, INVALID_DATE_FORMAT, INVALID_DATE_RANGE } from 'common/error';
import moment from 'moment-timezone';

export const FORMAT = (value, momentFormat) => {
  const parsed = moment(value);

  return parsed && parsed.isValid()
    ? parsed.format(momentFormat)
    : 'Invalid Date';
};

export const DEFAULT_FORMAT = (date) => {
  const parseDate = moment(date);

  return parseDate.isValid() === true ? parseDate.format('MM/DD/YYYY') : '';
};

export const validateDate = (date) => {

  if (!MM_DD_YYYY_DATE_REGEX?.test(date)) {
    return INVALID_DATE_FORMAT;
  }
  if (!moment(date, FORMAT_DATE).isValid() || INVALID_DATE_YEAR_REGEX?.test(date)) {
    return INVALID_DATE;
  }
  if (!DATE_YEAR_REGEX?.test(date)) {
    return INVALID_DATE_RANGE;
  }
  return true;
};
